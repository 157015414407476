import {createRouter, createWebHashHistory} from "vue-router/dist/vue-router";

/* lazy load */
const TransactionsView = () => import("@/views/TransactionsView.vue");
const InvoicesView = () => import("@/views/InvoicesView.vue");
const VgasView = () => import("@/views/VgasView.vue");
const OrganizationView = () => import("@/views/OrganizationView.vue");
const RegisterView = () => import("@/views/RegisterView.vue");
const PasswordForgotView = () => import("@/views/PasswordForgotView.vue");
const CashregistersView = () => import("@/views/CashregistersView.vue");

const routes = [
	{
		path: "/",
		name: "Home",
		meta: {title: "Willkommen"},
		component: TransactionsView,
	},
	{
		path: "/transactions",
		name: "Transactions",
		meta: {title: "Transaktionen"},
		component: TransactionsView,
	},
	{
		path: "/organization",
		name: "Organization",
		meta: {title: "Organisation"},
		component: OrganizationView,
	},
	{
		path: "/statements",
		name: "Statements",
		meta: {title: "VGAs"},
		component: VgasView,
	},
	{
		path: "/invoices",
		name: "Invoices",
		meta: {title: "Rechnungen"},
		component: InvoicesView,
	},
	{
		path: "/cashregisters",
		name: "Cashregisters",
		meta: {title: "Kassenschnitte"},
		component: CashregistersView,
	},
	{
		path: "/register/:token",
		name: "Register",
		meta: {title: "Registrierung"},
		component: RegisterView,
	},
	{
		path: "/passwordreset",
		name: "PasswordReset",
		meta: {title: "Passwort zurücksetzen"},
		component: PasswordForgotView,
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes: routes,
	linkExactActiveClass: 'is-active',
	scrollBehavior() {
		return {
			top: 0,
			left: 0,
			behavior: "smooth",
		};
	},
});

export default router;
