
import {defineComponent, ref} from "vue";
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {BookmarkAltIcon, FireIcon, HomeIcon, InboxIcon, MenuIcon, UserIcon, XIcon} from '@heroicons/vue/outline'

export default defineComponent({
	name: "MobileNav",
	components: {
		TransitionChild,
		TransitionRoot,
		Dialog,
		DialogPanel,
		BookmarkAltIcon,
		FireIcon,
		HomeIcon,
		InboxIcon,
		MenuIcon,
		UserIcon,
		XIcon
	},
	props: {
		navigation: {
			required: true,
			type: Array
		},
	},
	setup() {
		const mobileMenuOpen = ref(false);
		const openMobileMenu = () => {
			mobileMenuOpen.value = true;
		};
		const closeMobileMenu = () => {
			mobileMenuOpen.value = false;
		};

		const user = ref({
			imageUrl: ""
		});

		const logout = () => {
			window.localStorage.setItem("auth_token", "");
		}

		return {
			mobileMenuOpen,
			openMobileMenu,
			closeMobileMenu,
			logout,
			user
		}
	}
})
