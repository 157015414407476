import {
	ADD_SELECTED_BRANCHLOCATIONS,
	ADD_SELECTED_CARD_TYPES,
	ADD_SELECTED_CUSTOMER,
	ADD_TYPED_CASHREGISTER_AMOUNT_FROM,
	ADD_TYPED_CASHREGISTER_AMOUNT_TO,
	ADD_TYPED_CASHREGISTER_TERMINAL_ID,
	ADD_TYPED_TERMINAL_ID,
	ADD_TYPED_TRANSACTION_AMOUNT_FROM,
	ADD_TYPED_TRANSACTION_AMOUNT_TO,
	ADD_TYPED_TRANSACTION_ID,
	ADD_TYPED_TRANSACTION_TIME_FROM,
	ADD_TYPED_TRANSACTION_TIME_TO,
	LOGIN_USER,
	RECEIVE_BRANCHLOCATIONS,
	RECEIVE_CASHREGISTERS,
	RECEIVE_CUSTOMERS,
	RECEIVE_INVOICES,
	RECEIVE_TERMINALS,
	RECEIVE_TRANSACTIONS, RECEIVE_USERS,
	RECEIVE_VGAS, RESET_FILTER,
	SET_SEARCH_TEXT,
	SET_SELECTED_CASHREGISTERCUT_DATERANGE,
	SET_SELECTED_DATERANGE
} from "@/store/constants";
import {GlobalState} from "@/store/types";
import {Branchlocation, Cashregister, Customer, Invoice, Terminal, TransactionSIX, User, Vga} from "@/api/types";

const mutations = {
	[LOGIN_USER](state: GlobalState) {
		state.isLoggedIn = true;
	},


	[RECEIVE_TRANSACTIONS](state: GlobalState, transactions: TransactionSIX[]) {
		state.transactions = transactions;
	},

	[RECEIVE_INVOICES](state: GlobalState, invoices: Invoice[]) {
		state.invoices = invoices.map((invoice: Invoice) => {
			invoice.link = "http://payjet-api/api/invoice/getDocument?id=" + invoice.id
			return invoice;
		});
	},

	[RECEIVE_VGAS](state: GlobalState, vgas: Vga[]) {
		state.vgas = vgas;
	},
	[RECEIVE_BRANCHLOCATIONS](state: GlobalState, branchlocations: Branchlocation[]) {
		state.branchlocations = branchlocations.sort((a: Branchlocation, b: Branchlocation) => a.locationname < b.locationname ? -1 : a.locationname > b.locationname ? 1 : 0);
	},
	[RECEIVE_CUSTOMERS](state: GlobalState, customers: Customer[]) {
		state.customers = customers.sort((a: Customer, b: Customer) => a.companyname < b.companyname ? -1 : a.companyname > b.companyname ? 1 : 0);
	},

	[RECEIVE_TERMINALS](state: GlobalState, terminals: Terminal[]) {
		state.terminals = terminals;
	},
	[RECEIVE_CASHREGISTERS](state: GlobalState, cashregisters: Cashregister[]) {
		state.cashregisters = cashregisters;
	},
	[RECEIVE_USERS](state: GlobalState, users: User[]) {
		state.users = users;
	},
	/* ADDER */
	[ADD_TYPED_TRANSACTION_ID](state: GlobalState, typedTransactionId: string) {
		state.transactionIdFilter = typedTransactionId;
	},
	[ADD_TYPED_TERMINAL_ID](state: GlobalState, typedTerminalId: string) {
		state.transactionTerminalIdFilter = typedTerminalId;
	},
	[ADD_TYPED_CASHREGISTER_TERMINAL_ID](state: GlobalState, typedTerminalId: string) {
		state.cashregisterTerminalIdFilter = typedTerminalId;
	},
	[ADD_TYPED_TRANSACTION_AMOUNT_FROM](state: GlobalState, amountFrom: number) {
		state.transactionAmountFromFilter = amountFrom;
	},
	[ADD_TYPED_TRANSACTION_AMOUNT_TO](state: GlobalState, amountTo: number) {
		state.transactionAmountToFilter = amountTo;
	},
	[ADD_TYPED_TRANSACTION_TIME_FROM](state: GlobalState, timeFrom: string) {
		state.transactionTimeFromFilter = timeFrom;
	},
	[RESET_FILTER](state: GlobalState) {
		state.cashregisterAmountFromFilter = 0;
		state.cashregisterAmountToFilter = 0;
		state.cashregisterTerminalIdFilter = "";
		state.transactionIdFilter = "";
		state.transactionTerminalIdFilter = "";
		state.transactionLocationFilter = "";
		state.transactionCurrencyFilter = "";
		state.transactionAmountFromFilter = 0;
		state.transactionAmountToFilter = 0;
		state.transactionTransactionStateFilter = "";
		state.transactionCardTypeFilter = [];
		state.transactionTimeFromFilter = "";
		state.transactionTimeToFilter = "";
		state.selectedBranchlocation =null;
		state.cashregistercutDateRange = [];
	},
	[ADD_TYPED_TRANSACTION_TIME_TO](state: GlobalState, timeTo: string) {
		state.transactionTimeToFilter = timeTo;
	},
	[ADD_SELECTED_CARD_TYPES](state: GlobalState, cardTypes: string[]) {
		state.transactionCardTypeFilter = cardTypes;
	},
	[ADD_SELECTED_CUSTOMER](state: GlobalState, customers: Customer) {
		state.selectedCustomer = customers;
	},
	[SET_SELECTED_DATERANGE](state: GlobalState, dateRange: any) {
		state.dateRange = dateRange;
	},
	[ADD_TYPED_CASHREGISTER_AMOUNT_FROM](state: GlobalState, amountFrom: number) {
		state.cashregisterAmountFromFilter = amountFrom;
	},
	[ADD_TYPED_CASHREGISTER_AMOUNT_TO](state: GlobalState, amountTo: number) {
		state.cashregisterAmountToFilter = amountTo;
	},
	[SET_SELECTED_CASHREGISTERCUT_DATERANGE](state: GlobalState, dateRange: any) {
		state.cashregistercutDateRange = dateRange;
	},
	[SET_SEARCH_TEXT](state: GlobalState, searchValue: string) {
		state.searchValue = searchValue;
	},
	[ADD_SELECTED_BRANCHLOCATIONS](state: GlobalState, branchloctaion: Branchlocation) {
		state.selectedBranchlocation = branchloctaion;
	}
};

export default mutations;
