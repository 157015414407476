import axios from "axios";
import {Customer} from "@/api/types";
import getApiDomain from "@/api/getApiDomain";

const getUsers = async (params?: string) => {
    const response = await axios.get<Customer[]>(getApiDomain() + "/api/user", {
        headers: {
            'X-HTTP-AUTHORIZATION': `Authenticate ${localStorage.getItem("auth_token")}`,
            'Access-Control-Allow-Origin': '*',
        },
        params: params
    }).catch(function (e) {
        const error = e.toJSON();
        if(error.status !== 500){
            throw e;
        }
    });
    if(!response){
        return [];
    }
    return response.data;
};

export default getUsers;
