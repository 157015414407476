export const LOGIN_USER = "LOGIN_USER";
export const RECEIVE_TERMINALS = "RECEIVE_TERMINALS";
export const FETCH_TERMINALS = "FETCH_TERMINALS";
export const RECEIVE_TRANSACTIONS = "RECEIVE_TRANSACTIONS";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const FILTERED_TRANSACTIONS = "FILTERED_TRANSACTIONS";
export const SUM_AMOUNT_TRANSACTIONS = "SUM_AMOUNT_TRANSACTIONS";
export const INCLUDE_TRANSACTION_BY_TRANSACTION_ID = "INCLUDE_TRANSACTION_BY_TRANSACTION_ID";
export const ADD_TYPED_TRANSACTION_ID = "ADD_TYPED_TRANSACTION_ID";
export const ADD_TYPED_TERMINAL_ID = "ADD_TYPED_TERMINAL_ID";
export const ADD_TYPED_TRANSACTION_AMOUNT_FROM = "ADD_TYPED_TRANSACTION_AMOUNT_FROM";
export const ADD_TYPED_TRANSACTION_AMOUNT_TO = "ADD_TYPED_TRANSACTION_AMOUNT_TO";
export const INCLUDE_TRANSACTION_BY_TERMINAL_ID = "INCLUDE_TRANSACTION_BY_TERMINAL_ID";
export const INCLUDE_TRANSACTION_BY_LOCATION = "INCLUDE_TRANSACTION_BY_LOCATION";
export const INCLUDE_TRANSACTION_BY_CURRENCY = "INCLUDE_TRANSACTION_BY_CURRENCY";
export const INCLUDE_TRANSACTION_BY_AMOUNT = "INCLUDE_TRANSACTION_BY_AMOUNT";
export const INCLUDE_TRANSACTION_BY_TIME = "INCLUDE_TRANSACTION_BY_TIME";
export const INCLUDE_TRANSACTION_BY_STATE = "INCLUDE_TRANSACTION_BY_STATE";
export const INCLUDE_TRANSACTION_BY_CARD_TYPE = "INCLUDE_TRANSACTION_BY_CARD_TYPE";
export const UNIQUE_CARD_TYPES = "UNIQUE_CARD_TYPES";
export const ADD_SELECTED_CARD_TYPES = "ADD_SELECTED_CARD_TYPES";
export const INCLUDE_TRANSACTION_BY_TYPE = "INCLUDE_TRANSACTION_BY_TYPE";

export const RECEIVE_INVOICES = "RECEIVE_INVOICES";
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FILTERED_INVOICES = "FILTERED_INVOICES";
export const SUM_AMOUNT_INVOICES = "SUM_AMOUNT_INVOICES";

export const RECEIVE_BRANCHLOCATIONS = "RECEIVE_BRANCHLOCATIONS";
export const FETCH_BRANCHLOCATIONS = "FETCH_BRANCHLOCATIONS";
export const UNIQUE_BRANCHLOCATIONS = "UNIQUE_BRANCHLOCATIONS";
export const FILTERED_BRANCHLOCATIONS = "FILTERED_BRANCHLOCATIONS";
export const ADD_SELECTED_CUSTOMER = "ADD_SELECTED_CUSTOMER";
export const SELECTED_CUSTOMER = "ADD_SELECTED_CUSTOMER";

export const FETCH_VGAS = "FETCH_VGAS";
export const RECEIVE_VGAS = "RECEIVE_VGAS";
export const FILTERED_VGAS = "FILTERED_VGAS";

export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const UNIQUE_CUSTOMERS = "UNIQUE_CUSTOMERS";
export const ADD_SELECTED_BRANCHLOCATIONS = "ADD_SELECTED_BRANCHLOCATIONS";

export const FETCH_USERS = "FETCH_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const USER_SEARCH_PARAMS = "USER_SEARCH_PARAMS";
export const FILTERED_USERS = "FILTERED_USERS";

export const SET_SELECTED_DATERANGE = "SET_SELECTED_DATERANGE";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SEARCH_PARAMS = "SEARCH_PARAMS";

export const SET_SELECTED_CASHREGISTERCUT_DATERANGE = "SET_SELECTED_CASHREGISTERCUT_DATERANGE";
export const INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE = "INCLUDE_TRANSACTION_BY_CASHREGISTERCUT_DATE";

export const FETCH_CASHREGISTERS = "FETCH_CASHREGISTERS";
export const RECEIVE_CASHREGISTERS = "RECEIVE_CASHREGISTERS";
export const FILTERED_CASHREGISTERS = "FILTERED_CASHREGISTERS";

export const ADD_TYPED_CASHREGISTER_TERMINAL_ID = "ADD_TYPED_CASHREGISTER_TERMINAL_ID";
export const ADD_TYPED_CASHREGISTER_AMOUNT_FROM = "ADD_TYPED_CASHREGISTER_AMOUNT_FROM";
export const ADD_TYPED_CASHREGISTER_AMOUNT_TO = "ADD_TYPED_CASHREGISTER_AMOUNT_TO";
export const ADD_TYPED_TRANSACTION_TIME_FROM = "ADD_TYPED_TRANSACTION_TIME_FROM";
export const ADD_TYPED_TRANSACTION_TIME_TO = "ADD_TYPED_TRANSACTION_TIME_TO";
export const SUM_AMOUNT_CASHREGISTERS = "SUM_AMOUNT_CASHREGISTERS";
export const INCLUDE_CASHREGISTER_BY_TERMINAL_ID = "INCLUDE_CASHREGISTER_BY_TERMINAL_ID";
export const INCLUDE_CASHREGISTER_BY_AMOUNT = "INCLUDE_CASHREGISTER_BY_AMOUNT";
export const INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE = "INCLUDE_CASHREGISTER_BY_CASHREGISTERCUT_DATE";

export const RESET_FILTER = "RESET_FILTER";
