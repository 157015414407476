import {GlobalState} from "@/store/types";

const state = (): GlobalState => {
	return {
		cashregisters: [],
		cashregisterAmountFromFilter: 0,
		cashregisterAmountToFilter: 0,
		cashregisterTerminalIdFilter: "",
		isLoggedIn: false,
		terminals: [],
		customers: [],
		transactions: [],
		invoices: [],
		vgas: [],
		branchlocations: [],
		transactionIdFilter: "",
		transactionTerminalIdFilter: "",
		transactionLocationFilter: "",
		transactionCurrencyFilter: "",
		transactionAmountFromFilter: 0,
		transactionAmountToFilter: 0,
		transactionTransactionStateFilter: "",
		transactionCardTypeFilter: [],
		selectedBranchlocations: [],
		selectedBranchlocation: null,
		selectedCustomer: null,
		transactionColumnConfig: undefined,
		loginToken: "",
		dateRange: "",
		cashregistercutDateRange: [],
		searchValue: "",
		transactionTimeFromFilter: "",
		transactionTimeToFilter: "",
		users: []
	};
};
export default state;
