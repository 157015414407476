<template>
	<register-view v-if="registerToken" :registerToken="registerToken"/>
	<password-forgot-view v-if="(pathname === '/passwordreset')" :token="resetToken"/>
	<login-view v-if="!loggedIn"/>
	<div v-if="loggedIn" class="h-full flex">

		<desktop-nav
			:navigation="navigation"
			:user="user"
		/>

		<div class="flex-1 min-w-0 flex flex-col overflow-hidden">
			<!-- Mobile top navigation -->
			<mobile-nav
				:navigation="navigation"
				:user="user"
			/>

			<main class="flex-1 flex">
				<!-- Primary column -->
				<section aria-labelledby="primary-heading"
						 class="flex-1 h-full flex flex-col overflow-y-auto overflow-x-hidden lg:order-first"
				>

					<router-view/>
					<!-- Your content -->
				</section>

			</main>
		</div>
	</div>
</template>

<script>
import {
	BookmarkAltIcon,
	BriefcaseIcon,
	ChartBarIcon,
	ClipboardIcon,
	CollectionIcon,
	FireIcon,
	HomeIcon,
	InboxIcon,
	MenuIcon,
	UserIcon,
	XIcon
} from '@heroicons/vue/outline'
import {defineComponent, onMounted, ref} from "vue";
import MobileNav from "@/component/Navigation/MobileNav";
import DesktopNav from "@/component/Navigation/DesktopNav";
import {useFetchBranchlocationsDispatch, useFetchCustomersDispatch} from "@/store/composables";
import LoginView from "@/views/LoginView";
import RegisterView from "@/views/RegisterView";
import {useIdle} from "@vueuse/core";
import PasswordForgotView from "@/views/PasswordForgotView.vue";

export default defineComponent({
	name: "App",
	components: {
		PasswordForgotView,
		LoginView,
		RegisterView,
		DesktopNav,
		MobileNav,
		BookmarkAltIcon,
		FireIcon,
		HomeIcon,
		InboxIcon,
		MenuIcon,
		UserIcon,
		XIcon,
		useIdle
	},
	watch: {
		$route: {
			immediate: true,
			handler(to, from) {
				document.title = to.meta.title || 'Pay-Jet Kundenportal';
			}
		}
	},
	setup() {

		onMounted(useFetchCustomersDispatch)
		onMounted(useFetchBranchlocationsDispatch)

		const {idle} = useIdle(1000 * 60 * 30);

		const loggedIn = ref(!!window.localStorage.getItem("auth_token"));
		const user = ref({
			loggedIn: loggedIn,
			name: "",
			email: "",
			imageUrl: "",
		});
		setInterval(() => {
			const token = window.localStorage.getItem("auth_token");
			loggedIn.value = !!token;
			if (idle.value === true) {
				window.localStorage.removeItem("auth_token");
			}
		}, 100);

		const urlParams = new URLSearchParams(window.location.search);
		const registerToken = urlParams.get('register_token');
		const resetToken = urlParams.get('token');
		const pathname = window.location.pathname;

		const navigation = ref([
			{
				name: 'Transactions',
				href: '/transactions',
				meta: {title: "Transaktionen"},
				icon: ChartBarIcon
			},
			{
				name: 'Invoices',
				href: '/invoices',
				meta: {title: "Rechnungen"},
				icon: ClipboardIcon
			},
			// {name: 'Statements', href: '/statements', icon: CollectionIcon},
			{
				name: 'Organization',
				href: '/organization',
				meta: {title: "Organisation"},
				icon: BriefcaseIcon
			},
			{
				name: 'Cashregisters',
				href: '/cashregisters',
				meta: {title: "Kassenschnitte"},
				icon: CollectionIcon
			}
		]);


		return {
			user,
			loggedIn,
			navigation,
			registerToken,
			resetToken,
			idle,
			pathname
		}
	}
});
</script>
