import axios from "axios";
import getApiDomain from "@/api/getApiDomain";

const passwordresetmail = async (email: string) => {
	const response = await axios.post(
		getApiDomain() + "/forgot",
		{
			email: email
		},
		{
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		}
	);

	if (response.data.success) {
		window.setTimeout(function () {
			window.location.href = 'login';
		}, 2000);
	}

	return response.data.message;
};

export default passwordresetmail;
