import axios from "axios";
import {TransactionSIX} from "@/api/types";
import getApiDomain from "@/api/getApiDomain";

const getCashRegisters = async (params?: any) => {
	const response = await axios.get<TransactionSIX[]>(getApiDomain() + "/api/transactionlog/getCashRegister", {
		headers: {
			'X-HTTP-AUTHORIZATION': `Authenticate ${localStorage.getItem("auth_token")}`,
			'Access-Control-Allow-Origin': '*',
		},
		params: params
	}).catch(function (e) {
		const error = e.toJSON();
		if(error.status !== 500){
			throw e;
		}
	});
	if(!response){
		return [];
	}
	return response.data;
};

export default getCashRegisters;
