
import {defineComponent, ref} from "vue";
import {Menu, MenuButton, MenuItems} from "@headlessui/vue";

export default defineComponent({
	name: "UserMenu",
	components: {Menu, MenuButton, MenuItems},
	props: {},
	setup() {

		const user = ref({
			imageUrl: ""
		});

		const logout = () => {
			window.localStorage.setItem("auth_token", "");
		}

		return {
			logout,
			user
		}

	}
})
