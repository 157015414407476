import {createApp} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faAngleDown, faAngleUp, faSearch,} from "@fortawesome/free-solid-svg-icons";

import App from "@/App.vue";
import "@/assets/tailwind.css";
import router from "@/router";
import store, {key} from "@/store";
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import getApiDomain from "@/api/getApiDomain";

library.add(faSearch);
library.add(faAngleUp);
library.add(faAngleDown);

const app = createApp(App);

if (getApiDomain().includes("https")) {
	Sentry.init({
		app,
		dsn: "https://847d100b228f414aae51ab4b7840d011@sentry02.allbytes.net/22",
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: [
					"localhost",
					"localhost:8080",
					"sentry02.allbytes.net",
					"payjet-kundenportal.allbytes.run",
					/^\//
				],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}


app.use(store, key)
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount("#app");
