import axios from "axios";
import {AxiosError} from "axios";
import getApiDomain from "@/api/getApiDomain";

const login = async (email: string, password: string) => {
	try {
		const response = await axios.post(
			getApiDomain() + "/login",
			{
				email: email,
				password: password
			},
			{
				headers: {
					'Access-Control-Allow-Origin': '*',
				}
			}
		);

		if (response.data && response.data.token) {
			window.localStorage.setItem("auth_token", response.data.token);
			window.localStorage.setItem("user_id", response.data.id);
			window.localStorage.setItem("user_role_id", response.data.role_id);
			window.location.reload();
			return "";
		} else {
			console.error(response);
			return "Login fehlgeschlagen";
		}
	}catch(error){
		console.error(error)
		if(error instanceof AxiosError){
			return "Login fehlgeschlagen: "+error.message;
		}else{
			return "Login fehlgeschlagen";
		}
	}


};

export default login;
