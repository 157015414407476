
import {computed, defineComponent, ref} from "vue";
import register from "@/api/register";

export default defineComponent({
	name: "RegisterView",
	props: {
		registerToken: {
			required: true
		}
	},

	setup(props) {

		const email = ref("");
		const password = ref("");
		const passwordVerify = ref("");
		const message = ref("");
		const doRegister = () => {
			message.value = "";
			if (checkValues.value) {
				register(email.value, props.registerToken, password.value).then((registerMessage) => {
					message.value = registerMessage;
				});
			}
		}
		const checkPassword = computed(() => {
			if (password.value && passwordVerify.value) {
				return password.value == passwordVerify.value;
			}
			return true;
		});

		const checkValues = computed(() => {
			return checkPassword.value && email.value;
		});


		return {
			email,
			password,
			passwordVerify,
			doRegister,
			checkPassword,
			checkValues,
			message
		}
	}
});
