import axios, {AxiosError} from "axios";
import {Branchlocation} from "@/api/types";
import getApiDomain from "@/api/getApiDomain";

const getBranchlocations = async () => {
	const response = await axios.get<Branchlocation[]>(
		getApiDomain() + "/api/branchlocation",
		{
			headers: {
				'X-HTTP-AUTHORIZATION': `Authenticate ${localStorage.getItem("auth_token")}`,
				'Access-Control-Allow-Origin': '*',
			},
			validateStatus: function (status) {
				return status < 500; // Resolve only if the status code is less than 500
			}
		}
	).catch(function (e) {
		const error = e.toJSON();
		if(error.status !== 500){
			throw e;
		}
	});
	if(!response){
		return [];
	}
	return response.data;
};

export default getBranchlocations;
