import axios from "axios";
import getApiDomain from "@/api/getApiDomain";

const resetpassword = async (token: string, email: string, password: string, passwordVerify: string) => {
	const response = await axios.post(
		getApiDomain() + "/reset",
		{
			token: token,
			email: email,
			password: password,
			password_verify: passwordVerify
		},
		{
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		}
	);

	if (response.data.success) {
		window.setTimeout(function () {
			window.location.href = 'login';
		}, 3000);
	}

	return response.data.message;
};

export default resetpassword;
