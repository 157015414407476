
import {defineComponent, ref} from "vue";
import login from "@/api/login";

export default defineComponent({
	name: "LoginView",
	setup() {

		const email = ref("");
		const password = ref("");
		const loginerror = ref("");
		const dologin = () => {
			loginerror.value = "";
			login(email.value, password.value).then((msg: string) => {
				loginerror.value = msg;
			});
		}

		return {
			email,
			password,
			dologin,
			loginerror
		}
	}
});
