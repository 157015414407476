import {Commit} from "vuex"

import {
	FETCH_BRANCHLOCATIONS,
	FETCH_CASHREGISTERS,
	FETCH_CUSTOMERS,
	FETCH_INVOICES,
	FETCH_TERMINALS,
	FETCH_TRANSACTIONS,
	FETCH_VGAS,
	FETCH_USERS,
	RECEIVE_BRANCHLOCATIONS,
	RECEIVE_CASHREGISTERS,
	RECEIVE_CUSTOMERS,
	RECEIVE_INVOICES,
	RECEIVE_TERMINALS,
	RECEIVE_TRANSACTIONS,
	RECEIVE_VGAS,
	RECEIVE_USERS
} from "@/store/constants";
import getTransactions from "@/api/get.transactions";
import getInvoices from "@/api/get.invoices";
import getVgas from "@/api/get.vgas";
import getBranchlocations from "@/api/get.branchlocations";
import getCustomers from "@/api/get.customers";
import getTerminals from "@/api/get.terminals";
import getCashregisters from "@/api/get.cashregisters";
import getUsers from "@/api/get.users";

interface Context {
	commit: Commit
}

const actions = {
	[FETCH_TRANSACTIONS]: async (context: Context, params?: any) => {
		context.commit(RECEIVE_TRANSACTIONS, await getTransactions(params));
	},
	[FETCH_TERMINALS]: async (context: Context) => {
		context.commit(RECEIVE_TERMINALS, await getTerminals());
	},
	[FETCH_INVOICES]: async (context: Context) => {
		context.commit(RECEIVE_INVOICES, await getInvoices());
	},
	[FETCH_CASHREGISTERS]: async (context: Context, params?: any) => {
		context.commit(RECEIVE_CASHREGISTERS, await getCashregisters(params));
	},
	[FETCH_VGAS]: async (context: Context) => {
		context.commit(RECEIVE_VGAS, await getVgas());
	},
	[FETCH_BRANCHLOCATIONS]: async (context: Context) => {
		context.commit(RECEIVE_BRANCHLOCATIONS, await getBranchlocations());
	},
	[FETCH_CUSTOMERS]: async (context: Context) => {
		context.commit(RECEIVE_CUSTOMERS, await getCustomers());
	},
	[FETCH_USERS]: async (context: Context, params?: any) => {
		context.commit(RECEIVE_USERS, await getUsers(params));
	}
};

export default actions;
