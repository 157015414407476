import {useStore} from "vuex";
import {computed} from "vue";
import {
	FETCH_BRANCHLOCATIONS,
	FETCH_CASHREGISTERS,
	FETCH_CUSTOMERS,
	FETCH_INVOICES,
	FETCH_TRANSACTIONS,
	FETCH_VGAS,
	FETCH_USERS,
	FILTERED_BRANCHLOCATIONS,
	FILTERED_CASHREGISTERS,
	FILTERED_INVOICES,
	FILTERED_TRANSACTIONS,
	FILTERED_VGAS,
	SELECTED_CUSTOMER,
	SUM_AMOUNT_CASHREGISTERS,
	SUM_AMOUNT_INVOICES,
	SUM_AMOUNT_TRANSACTIONS,
	UNIQUE_CARD_TYPES,
	UNIQUE_CUSTOMERS, FILTERED_USERS,
} from "@/store/constants";

import {Cashregister, Invoice, TransactionSIX, User, Vga} from "@/api/types";
import {key} from "@/store"


export const useFilteredTransactions = () => {
	const store = useStore(key);
	return computed<TransactionSIX[]>(() => store.getters[FILTERED_TRANSACTIONS]);
};
export const useSumAmountTransactions = (type: string) => {
	const store = useStore(key);
	return computed<number>(() => store.getters[SUM_AMOUNT_TRANSACTIONS][type]);
};
export const useSumAmountTransactionsFull = () => {
	const store = useStore(key);
	return computed<any>(() => store.getters[SUM_AMOUNT_TRANSACTIONS]);
};

export const useFilteredInvoices = () => {
	const store = useStore(key);
	return computed<Invoice[]>(() => store.getters[FILTERED_INVOICES]);
};


export const useFilteredCashregisters = () => {
	const store = useStore(key);
	return computed<Cashregister[]>(() => store.getters[FILTERED_CASHREGISTERS]);
};
export const useSumAmountCashregisters = (type: string) => {
	const store = useStore(key);
	return computed<number>(() => store.getters[SUM_AMOUNT_CASHREGISTERS][type]);
};
export const useSumAmountCashregistersFull = () => {
	const store = useStore(key);
	return computed<any>(() => store.getters[SUM_AMOUNT_CASHREGISTERS]);
};


export const useFilteredVgas = () => {
	const store = useStore(key);
	return computed<Vga[]>(() => store.getters[FILTERED_VGAS]);
};
export const useSumAmountInvoices = () => {
	const store = useStore(key);
	return computed<number>(() => store.getters[SUM_AMOUNT_INVOICES]);
};

export const getUniqueCardTypes = () => {
	const store = useStore(key);
	return computed<Set<String>>(() => store.getters[UNIQUE_CARD_TYPES]);
}

export const useFilteredLocations = () => {
	const store = useStore(key);
	return computed(() => store.getters[FILTERED_BRANCHLOCATIONS]);
}

export const useCustomers = () => {
	const store = useStore(key);
	return computed(() => store.getters[UNIQUE_CUSTOMERS]);
}
export const useSelectedCustomer = () => {
	const store = useStore(key);
	return computed(() => store.getters[SELECTED_CUSTOMER]);
}

/* ACTIONS */
export const useFetchTransactionsDispatch = () => {
	const store = useStore(key);
	store.dispatch(FETCH_TRANSACTIONS);
};

export const useFetchInvoicesDispatch = () => {
	const store = useStore(key);
	store.dispatch(FETCH_INVOICES);
};
export const useFetchCashregistersDispatch = () => {
	const store = useStore(key);
	store.dispatch(FETCH_CASHREGISTERS);
};

export const useFetchVgasDispatch = () => {
	const store = useStore(key);
	store.dispatch(FETCH_VGAS);
};

export const useFetchCustomersDispatch = () => {
	const store = useStore(key);
	store.dispatch(FETCH_CUSTOMERS);
};

export const useFetchBranchlocationsDispatch = () => {
	const store = useStore(key);
	store.dispatch(FETCH_BRANCHLOCATIONS);
};
export const useFilteredUsers = () => {
	const store = useStore(key);
	return computed<User[]>(() => store.getters[FILTERED_USERS]);
}