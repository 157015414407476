import axios from "axios";
import getApiDomain from "@/api/getApiDomain";

const register = async (email: string, registerToken: any, password: string) => {

	const response = await axios.post(
		getApiDomain() + "/register",
		{
			email: email,
			registerToken: registerToken,
			password: password
		},
		{
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		}
	);

	if (response.data.success) {
		//await router.push({name: 'login'})
		window.location.href = 'login';
	}

	if (!response.data.success) {
		return response.data.message;
	}
};

export default register;
