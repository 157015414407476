
import {defineComponent} from "vue";
import {BookmarkAltIcon, FireIcon, HomeIcon, InboxIcon, MenuIcon, UserIcon, XIcon} from '@heroicons/vue/outline'
import UserMenu from "@/component/Navigation/UserMenu.vue";

export default defineComponent({
	name: "DesktopNav",
	components: {UserMenu, BookmarkAltIcon, FireIcon, HomeIcon, InboxIcon, MenuIcon, UserIcon, XIcon,},
	props: {
		navigation: {
			required: true,
		},
		user: {
			required: true
		}
	},
	setup() {

	}
})
