
import {defineComponent, ref} from "vue";
import passwordresetmail from "@/api/passwordresetmail";
import resetpassword from "@/api/resetpassword";
import {ArrowLeftIcon} from "@heroicons/vue/solid";

export default defineComponent({
	name: "PasswordForgotView",
	components: {ArrowLeftIcon},
	props: {
		token: {
			type: String,
			required: false,
			default: "",
		}
	},
	setup(props) {

		const token = props.token;
		const email = ref("");
		const password = ref("");
		const passwordVerify = ref("");
		const passwordresetmailmessage = ref("");
		const passwordresetmessage = ref("");

		const doPasswordResetMail = () => {
			passwordresetmailmessage.value = "";
			passwordresetmail(email.value).then((msg: string) => {
				passwordresetmailmessage.value = msg;
			});
		}
		const doResetPassword = () => {
			passwordresetmessage.value = "";
			resetpassword(token, email.value, password.value, passwordVerify.value).then((msg: string) => {
				passwordresetmessage.value = msg;
			});
		}

		return {
			email,
			password,
			passwordVerify,
			doPasswordResetMail,
			doResetPassword,
			passwordresetmailmessage,
			passwordresetmessage,
			token
		}
	}
});
